import {EnvironmentType} from "src/app/enums/enums";

export const environment = {
  production: false,
  apiUrl: 'https://gbemaapidev.clavisco.com/',
  name: `Biotec`,
  type: EnvironmentType.Development,
  env: 'Development',
  recatchaSiteKey: '6LcMlsIpAAAAAKBfVDv-HijjIAiyEvsmPzlcTDZE',
  logrocket: ''
};
