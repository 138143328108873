import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Html5Qrcode, Html5QrcodeScanner} from "html5-qrcode";
import {Html5QrcodeResult} from "html5-qrcode/src/core";
import {MatDialogRef} from "@angular/material/dialog";
import {Subject} from "rxjs";

@Component({
  selector: 'app-camera-scanner',
  templateUrl: './camera-scanner.component.html',
  styleUrls: ['./camera-scanner.component.scss']
})
export class CameraScannerComponent implements OnInit, OnDestroy {

  /**
   * Scanner controller
   */
  private html5Qrcode!: Html5Qrcode;

  /**
   * Emit and event when something is scanned
   */
  onScan$!: Subject<string>;

  constructor(private matDialogRef: MatDialogRef<CameraScannerComponent>)
  {
    this.InitVariables();
  }

  ngOnInit(): void {
    this.SetUpScanner();
  }

  ngOnDestroy() : void
  {
    this.html5Qrcode.stop();
  }

  /**
   * Initialize the component variables
   * @constructor
   * @private
   */
  private InitVariables(): void
  {
    this.onScan$ = new Subject<string>();
  }

  /**
   * Continue scanning
   * @constructor
   */
  ResumeScan(): void
  {
    this.html5Qrcode.resume();
  }

  /**
   * Set up the scanner listener
   * @constructor
   * @private
   */
  private SetUpScanner(): void
  {
    Html5Qrcode.getCameras()
      .then(devices => {

        if (devices && devices.length)
        {
          let cameraId: string = devices[0].id;

          this.html5Qrcode = new Html5Qrcode("reader");

          this.StartScanning(cameraId)
        }
      }).catch(err => {
      console.error(err)
    });
  }

  /**
   * Start scanning with the selected camera
   * @param pCameraId Camera id
   * @constructor
   * @private
   */
  private StartScanning(pCameraId: string): void
  {
    this.html5Qrcode.start(
      {facingMode: 'environment'},
      { fps: 15, qrbox: { width: 250, height: 250 } },
      this.OnCodeScanned,
      undefined
    )
    .catch((err) => {
      console.error("An error occurs trying to start the scanning. Details: ", err);
    });
  }

  /**
   * On success scan handler
   * @param pDecodedText Decoded code scanned
   * @param pResult Scan result
   * @constructor
   * @private
   */
  private OnCodeScanned = (pDecodedText: string, pResult: Html5QrcodeResult) =>
  {
    this.html5Qrcode.pause();

    this.onScan$.next(pDecodedText);
  }

  /**
   * Close the camera scanner modal
   * @constructor
   */
  FinalizeScanning(): void
  {
    this.matDialogRef.close();
  }
}
