<section class="main-container camera-modal">
  <section class="scanner-container">
    <div id="reader" style="width: 100%;"></div>
  </section>
  <section class="actions-container">
    <button mat-fab color="primary" (click)="FinalizeScanning()">
      <mat-icon>
        close
      </mat-icon>
    </button>
  </section>
</section>
