import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {NavigationEnd, Router} from "@angular/router";
import {SharedService} from "@app/shared/shared.service";
type RouteHandler = (subRoute: string) => string;

@Injectable()
export class RoutingInterceptor implements HttpInterceptor {
  /**
   * Use data for map routes for diferent routings events
   */
  private routeMap: Map<RegExp, RouteHandler>;
  constructor(private router: Router, private sharedService: SharedService,) {
    this.routeMap = new Map([
      [/^purchases(?:\/([^?]+))?/, this.handlePurchases],
      [/^sales(?:\/documents)?(?:\/([^?]+))?/, this.handleSales],
      [/^inventory(?:\/([^?]+))?/, this.handleInventory],
      [/^master-data(?:\/([^?]+))?/, this.handleMasterData],
      [/^maintenance(?:\/([^?]+))?/, this.handleMaintenance],
      [/^activities(?:\/([^?]+))?/, this.handleActivities],
      [/^banks(?:\/([^?]+))?/, this.handleBanks],
      [/^terminals(?:\/([^?]+))?/, this.handleTerminals],
      [/^offline(?:\/([^?]+))?/, this.handleOffline],
      [/^route(?:\/([^?]+))?/, this.handleRoute],
      [/^home$/, () => 'Inicio'],
      [/^event-viewer$/, () => 'Visor de eventos']
    ]);
  }
  /**
   * Method to validate url for define title toolbar
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(() => {
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            const url = this.removeParams(event.url.slice(1)); // Remove leading slash and params
            for (const [regex, handler] of this.routeMap) {
              const match = url.match(regex);
              if (match) {
                const page = handler.call(this, match.length==1 ? match[0] : match[1].includes('/') ? match[1].split('/')[0] : match[1] || '');
                this.sharedService.SetCurrentPage(page);
                return;
              }
            }
          }
        });
      })
    );
  }

  /**
   * Method to remove params o the URL
   */
  private removeParams(url: string): string {
    return url.split('?')[0];
  }

  /**
   * Event to return data purchases
   */
  private handlePurchases = (subRoute: string): string => {
    const purchasesMap: { [key: string]: string } = {
      'order': 'Orden de compra',
      'good-receipt': 'Entradas de mercancías',
      'return-good': 'Devolución de mercancía',
      'invoice': 'Factura de proveedores',
      'down-payments': 'Factura anticipos',
      'approvals': 'Autorizaciones',
      'search-docs': 'Buscar documentos',
    };
    return purchasesMap[subRoute] || 'Compras';
  }

  /**
   * Event to return data sales
   */
  private handleSales = (subRoute: string): string => {
    const salesMap: { [key: string]: string } = {
      'approvals': 'Autorizaciones',
      'credit-memo': 'Notas de crédito',
      'orders': 'Orden',
      'invoices': 'Factura (Contado/Crédito)',
      'quotations': 'Cotización',
      'search-docs': 'Buscar documentos',
      'delivery': 'Entrega',
      'down-payments': 'Factura anticipos',
      'reserve-invoice': 'Factura de reserva',
      'cash-flow': 'Movimientos de dinero',
      'cash-closing': 'Cierres de caja'
    };
    return salesMap[subRoute] || 'Ventas';
  }

  /**
   * Event to return data inventory
   */
  private handleInventory = (subRoute: string): string => {
    const inventoryMap: { [key: string]: string } = {
      'search': 'Buscar documentos',
      'entry': 'Entrada de inventario',
      'output': 'Salida de inventario',
      'transfer-request': 'Solicitud de traslado',
      'transfer': 'Transferencia de stock',
      'counting': 'Recuento de inventario'
    };
    return inventoryMap[subRoute] || 'Inventario';
  }

  /**
   * Event to return data master-Data
   */
  private handleMasterData = (subRoute: string): string => {
    const masterDataMap: { [key: string]: string } = {
      'business-partners': 'Socios de negocios',
      'items': 'Artículos'
    };
    return masterDataMap[subRoute] || 'Datos maestros';
  }

  /**
   * Event to return data maintenance
   */
  private handleMaintenance = (subRoute: string): string => {
    const maintenanceMap: { [key: string]: string } = {
      'companies': 'Compañías',
      'users': 'Usuarios',
      'roles-users': 'Roles',
      'licenses': 'Licencias',
      'geo-roles-users': 'Geo Roles',
      'terminals': 'Terminales',
      'udfs': 'UDFs',
      'frequencies': 'Frecuencias',
      'activate-customers': 'Activación de clientes'
    };
    return maintenanceMap[subRoute] || 'Mantenimiento';
  }

  /**
   * Event to return data activities
   */
  private handleActivities = (subRoute: string): string => {
    const activitiesMap: { [key: string]: string } = {
      'search': 'Buscar Actividades',
      'create': 'Actividad'
    };
    return activitiesMap[subRoute] || 'Actividades';
  }

  /**
   * Event to return data banks
   */
  private handleBanks = (subRoute: string): string => {
    const banksMap: { [key: string]: string } = {
      'incoming-payment': 'Pagos recibidos',
      'outgoing-payment': 'Pagos efectuados',
      'cancel-payment': 'Anular pagos',
      'internal-reconciliation': 'Reconciliación interna'
    };
    return banksMap[subRoute] || 'Bancos';
  }

  /**
   * Event to return data terminals
   */
  private handleTerminals = (subRoute: string): string => {
    const terminalsMap: { [key: string]: string } = {
      'preclosing-cards': 'Precierres y cierres',
      'pendings': 'Transacciones pendientes',
      'print-void-cards': 'Reimpresión anulaciones'
    };
    return terminalsMap[subRoute] || 'Terminales';
  }

  /**
   * Event to return data offline for mobile
   */
  private handleOffline = (subRoute: string): string => {
    return subRoute === 'mobile' ? 'Movil' : 'Offline';
  }

  /**
   * Event to return data routes
   */
  private handleRoute = (subRoute: string): string => {
    const routeMap: { [key: string]: string } = {
      'new': 'Nueva ruta',
      'list': 'Mis rutas'
    };
    return routeMap[subRoute] || 'Ruta';
  }

}
