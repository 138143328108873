import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpStatusCode, HttpErrorResponse
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {Repository} from "@clavisco/core";
import {StorageKey} from "@app/enums/e-storage-keys";
import {environment} from "@Environment/environment";
import {CLModalType} from "@clavisco/alerts";

@Injectable()
export class TransactInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
        catchError((error: any) => {
          if(!`${error['error']}`.includes('null')) {
            if (`${error['error']['Message']}`.includes('Caused by', 0)) {
              error = `${error['error']['Message']}`.split('Caused by')[0];
            }
          }
          return throwError(error);
      }));
  }
}
